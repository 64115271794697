<template>
    <div class="page">
        <div class="banner">
            <img src="@/assets/images/topTitleBg.png" alt=""/>
            <div class="zxfw">
                <div class="zxfws">咨询服务</div>
            </div>
        </div>
        <div style="padding-top: 40px;width: 1200px;margin: 0 auto;">
            <div class="mtitle">咨询服务</div>
        </div>
        <div class="guantou">
            <div class="guantouf">
                <div class="guantouf_1" @click="goRouter('attestation',{title:'标准制定服务'})">
                    <div class="guantouf_1_1">企业标准制定修订</div>
                    <div class="guantouf_1_2">
                        标准制定服务
                    </div>
                    <div class="guantouf_2_1">了解更多</div>
                    <div class="hx"></div>
                    <i class="yuanquan el-icon-right"></i>
                </div>
            </div>
            <div class="guantout">
                <img src="@/assets/images/蒙版组 33.png" alt=""/>
            </div>
        </div>
        <div class="jiance">
            <div class="jiancet">
                <img src="@/assets/images/蒙版组 39.png" alt=""/>
            </div>
            <div class="jiancef">
                <div class="guantouf">
                    <div class="guantouf_1" @click="goRouter('attestation',{title:'上岗培训服务'})">
                        <div class="guantouf_1_1">检测人员上岗培训</div>
                        <div class="guantouf_1_2">
                            检验检测人员上岗培训服务
                        </div>
                        <div class="guantouf_2_1">了解更多</div>
                        <div class="hx"></div>
                        <i class="yuanquan el-icon-right"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="jp">
            <div class="jpf">
                <div class="guantouf">
                    <div class="guantouf_1" @click="goRouter('attestation',{title:'食品标签咨询'})">
                        <div class="guantouf_1_1">预包装食品标签咨询</div>
                        <div class="guantouf_1_2">
                            预包装食品标签及食品营养标签咨询服务
                        </div>
                        <div class="guantouf_2_1">了解更多</div>
                        <div class="hx"></div>
                        <i class="yuanquan el-icon-right"></i>
                    </div>
                </div>
            </div>
            <div class="jpt">
                <img src="@/assets/images/蒙版组 36.png" alt=""/>
            </div>
        </div>


        <!--    选择我们 s-->
        <zxhz></zxhz>
        <!--    选择我们 e-->
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        methods: {
            goRouter(roter, query) {
                this.$router.push({
                    name: roter,
                    query
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    * {
        margin: 0;
        padding: 0;
    }

    .banner {
        position: relative;
        width: 100%;
        height: 250px;
        background: linear-gradient(
                        90deg,
                        #0016ed 0%,
                        rgba(89, 104, 243, 0.65) 47%,
                        rgba(158, 167, 248, 0.38) 83%,
                        rgba(255, 255, 255, 0) 100%
        );
        display: flex;
        justify-content: center;
        opacity: 1;
    }

    .center, .center1 {
        height: 132px;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        //background: pink;
    }

    .center1 {
        height: 65px;
    }

    .word, .word1 {
        width: 87px;
        height: 32px;
        border: 0px solid #606060;
        opacity: 1;
        border-radius: 0px;
        padding-top: 94px;
        margin-left: 10px;
    }

    .word1 {
        padding-top: 41px;
    }

    .juxing, .juxing1 {
        width: 87px;
        height: 32px;
        border: 1px solid #606060;
        opacity: 1;
        border-radius: 0px;
        margin-top: 90px;
        margin-left: 0;
        position: absolute;
    }

    .juxing1 {
        margin-top: 35px;
    }

    .zxfw {
        position: absolute;
        width: 1200px;
        height: 250px;
        color: #fff;
        font-size: 50px;
        // z-index: 55;
    }

    .zxfw .zxfws {
        position: absolute;
        left: 15%;
        top: 50%;
    }

    .guantou {
        margin: 35px auto 0px;
        width: 1200px;
        height: 550px;
        display: flex;
        &:hover {
            color: #1320E8;
            cursor: pointer;

        }
    }

    .guantou .guantouf {
        width: 600px;
        height: 550px;
        background: #F5F5F5;
        &:hover {
            color: #1320E8;
            cursor: pointer;

        }
    }

    .guantouf .guantouf_1 .guantouf_1_1 {
        width: 473px;
        height: 70px;
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        //color: #1320E8;
        color: #606060;
        letter-spacing: 1px;
        opacity: 1;
        position: absolute;
        margin-top: 122px;
        margin-left: 102px;
        &:hover {
            color: #1320E8;
            cursor: pointer;

        }
    }

    .guantouf .guantouf_1 .guantouf_1_2 {
        height: 36px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 16px;
        //color: #1320E8;
        color: #606060;
        letter-spacing: 1px;
        opacity: 0.76;
        position: absolute;
        margin-left: 102px;
        margin-top: 203px;
        &:hover {
            color: #1320E8;
            cursor: pointer;

        }

    }

    .guantouf_2_1 {
        //width: 57px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 90px;
        //color: #1320E8;
        color: #606060;
        letter-spacing: 3px;
        opacity: 0.76;
        margin-top: 320px;
        margin-left: 102px;
        position: absolute;
        &:hover {
            color: #1320E8;
            cursor: pointer;

        }
    }

    .hx {
        width: 285px;
        height: 0px;
        border: 1px solid #606060;;
        opacity: 1;
        margin-top: 394px;
        margin-left: 102px;
        position: absolute;
        &:hover {
            cursor: pointer;
            border: 1px solid #1320E8;
        }
    }

    .yuanquan {
        width: 86px;
        height: 86px;
        border-radius: 50%;
        margin-top: 356px;
        margin-left: 360px;
        position: absolute;
        color: #707070;
        //&:hover {
        //  border: 1px solid #1320E8;
        //  cursor: pointer;
        //
        //}
    }

    .jiantou {
        width: 14px;
        height: 11px;
        opacity: 1;
        margin-top: 374px;
        margin-left: 373px;
        position: absolute;
        background: #7eaf0a;
    }

    .guantou .guantout {
        width: 600px;
        height: 550px;
        background: #333333;
        opacity: 1;
        overflow: hidden;
    }

    .jiance {
        margin: 0 auto;
        width: 1200px;
        display: flex;
        overflow: hidden;
        // background-color: antiquewhite;
    }

    .jiance .jiancet {
        width: 600px;
        height: 550px;
        background: #333333;
        opacity: 1;
        overflow: hidden;
        img:hover {
            transition: all 0.6s;
            transform: scale(1.1, 1.1);
        }
    }

    .jp {
        margin: 0 auto;
        width: 1200px;
        height: 550px;
        display: flex;
    }

    .jp .jpf {
        width: 600px;
        height: 550px;
    }

    .jp .jpt {
        width: 600px;
        height: 550px;
        background: #d1d3d4;
        opacity: 1;
        overflow: hidden;
        img:hover {
            transition: all 0.6s;
            transform: scale(1.1, 1.1);
        }
    }

    .footer {
        width: 100%;
        height: 447px;
        background: #FBFBFB;
        opacity: 1;
        margin-top: 88px;
    }

    .p {
        width: 1200px;
        height: 380px;
        margin: 0 auto;
        display: flex;
        .picture, .picture1 {
            width: 74px;
            height: 74px;
            margin-top: 90px;
            margin-left: 90px;
            position: absolute;
        }
        .picture1 {
            margin-top: 213px;
        }
        .fwzx_box, .fwzx_box1 {
            width: 125px;
            height: 43px;
            background: #F5F5F5;
            opacity: 1;
            margin-top: 110px;
            margin-left: 193px;
            position: absolute;
        }
        .fwzx_box1 {
            margin-top: 228px;
        }
        .fwzx, .fwzx1 {
            width: 109px;
            height: 28px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 600;
            line-height: 0px;
            color: #606060;
            letter-spacing: 5px;
            margin-top: 130px;
            margin-left: 206px;
            position: absolute;
            opacity: 1;
        }
        .fwzx1 {
            margin-top: 248px;
        }
        .person {
            width: 538px;
            height: 301px;
            opacity: 1;
            margin-left: 638px;
            overflow: hidden;
        }
        .blue {
            width: 24px;
            height: 229px;
            background: #1320E8;
            opacity: 1;
            border-radius: 0px;
            margin-top: 72px;
        }
    }

</style>
